body {
  overflow: hidden;
}

[role=presentation] {
  /* get rid of MUI's default Dialog z-index */
  z-index: unset !important; 
}

[role=progressbar] {
  /* max-width: 0;
  min-width: 100%; */
  width: 100%;
}

.react-joyride__overlay {
  height: 0;
}

::-webkit-scrollbar { display: none } 

input:focus::placeholder {
  color: transparent;
  transition: 0.2s;
}